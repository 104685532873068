import React from "react";
import theme from "theme";
import { Theme, Text, Strong, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"privacy-policy"} />
		<Helmet>
			<title>
				Casa Del Ritmo
			</title>
			<meta name={"description"} content={"Feel the rhythm, feel the vibe, and let Casa Del Ritmo be your Cuban guide!"} />
			<meta property={"og:title"} content={"Casa Del Ritmo"} />
			<meta property={"og:description"} content={"Feel the rhythm, feel the vibe, and let Casa Del Ritmo be your Cuban guide!"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65365b4547aa25001fb0ef04/images/Sports-Social-Green-Hills-1024x1024.jpeg?v=2023-10-23T11:42:12.119Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65365b4547aa25001fb0ef04/images/Group%2011.png?v=2023-10-23T11:47:26.776Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65365b4547aa25001fb0ef04/images/Group%2011.png?v=2023-10-23T11:47:26.776Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65365b4547aa25001fb0ef04/images/Group%2011.png?v=2023-10-23T11:47:26.776Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65365b4547aa25001fb0ef04/images/Group%2011.png?v=2023-10-23T11:47:26.776Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65365b4547aa25001fb0ef04/images/Group%2011.png?v=2023-10-23T11:47:26.776Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65365b4547aa25001fb0ef04/images/Group%2011.png?v=2023-10-23T11:47:26.776Z"} />
			<meta name={"msapplication-TileColor"} content={"https://uploads.quarkly.io/65365b4547aa25001fb0ef04/images/Group%2011.png?v=2023-10-23T11:47:26.776Z"} />
		</Helmet>
		<Components.TukazaeHeader />
		<Section padding="50px 0 80px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text as="h2" font="--headline1" md-font="--headline2" margin="20px 0 0 0">
				PRIVACY POLICY
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
				This Privacy Policy ("Policy") describes how zonitypokies.com ("we," "us," or "our") collects, uses, shares, and protects the personal information of visitors and users ("you" or "your") of the zonitypokies.com website and related services (collectively, the "Services").
				<br />
				<br />
				By using our Services, you agree to the practices described in this Policy. If you do not agree to this Policy, please do not use our Services.
				<br />
				<br />
				<Strong>
					1. Information We Collect
				</Strong>
				<br />
				<br />
				We may collect the following types of information:
				<br />
				<br />
				1.1 Personal Information: We may collect personally identifiable information such as your name, email address, postal address, and other information you voluntarily provide when you interact with our Services.
				<br />
				<br />
				1.2 Usage Information: We may collect non-personal information about your interactions with our Services, including but not limited to your IP address, browser type, operating system, and pages visited. We may also use cookies and similar technologies to collect this information.
				<br />
				<br />
				<Strong>
					2. How We Use Your Information
				</Strong>
				<br />
				<br />
				We use your information for the following purposes:
				<br />
				<br />
				2.1 Providing and Improving Services: We use your information to provide, maintain, and improve our Services, including responding to your inquiries and requests.
				<br />
				<br />
				2.2 Analytics: We use analytics tools to analyze user trends and preferences, which helps us improve our Services.
				<br />
				<Strong>
					<br />
					3. Sharing Your Information
				</Strong>
				<br />
				<br />
				We do not sell, rent, or trade your personal information to third parties. However, we may share your information in the following situations:
				<br />
				<br />
				3.1 Legal Requirements: We may disclose your information if required by law, regulation, legal process, or government request.
				<br />
				<br />
				3.2 Protection of Rights: We may disclose your information when we believe it is necessary to protect our rights, privacy, safety, or property, or to respond to an emergency that threatens the safety of any person.
				<br />
				<br />
				<Strong>
					4. Your Choices
				</Strong>
				<br />
				<br />
				You have the following choices regarding your personal information:
				<br />
				<br />
				4.1 Access and Correction: You may access and correct your personal information by contacting us at contact@zonitypokies.com.
				<br />
				<br />
				4.2 Opt-Out: You may opt-out of receiving promotional emails from us by following the instructions in those emails.
				<br />
				<br />
				<Strong>
					5. Security
				</Strong>
				<br />
				<br />
				We use reasonable administrative, technical, and physical measures to protect your information. However, no method of transmission over the internet or electronic storage is entirely secure, and we cannot guarantee absolute security.
				<br />
				<br />
				<Strong>
					6. Children's Privacy
				</Strong>
				<br />
				<br />
				Our Services are not intended for individuals under the age of 13. If you believe that we have inadvertently collected information from a child under 13, please contact us, and we will promptly delete such information.
				<br />
				<br />
				<Strong>
					7. Changes to this Privacy Policy
				</Strong>
				<br />
				<br />
				We may update this Privacy Policy from time to time. Please review this Policy periodically to stay informed about our data practices.
				<br />
				<br />
				<Strong>
					8. Contact Us
				</Strong>
				<br />
				<br />
				If you have any questions, concerns, or requests regarding this Privacy Policy or our data practices, please contact us at contact@tukazae.com
			</Text>
		</Section>
		<Components.TukazaeFooter />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});